<template>
  <exterior-page-content-container class="h-1/2">
    <p class="text-2xl font-bold text-center text-primary">
      Create an Account
    </p>

    <notification-banner
      v-if="accountCreated"
      color="green"
      class="mb-64"
    >
      <p>Congratulations!</p>
      <p>
        Congratulations, you have successfully created your account and
        confirmation email has been sent to you. Please check your email to get started.
      </p>
    </notification-banner>

    <div
      v-if="!accountCreated"
      :class="`
      flex flex-col
      items-center flex-shrink-0 w-full px-2 py-1 m-1
      text-sm font-bold border-gray-300 rounded
    `"
    >
      <div class="flex flex-col">
        <a
        href="/api/user/login_google"
        class="flex px-2 py-1 m-1 text-sm font-bold bg-white border border-gray-300 rounded"
      >
        <img src="/img/google/google-icon.png" alt="google logo" class="w-5 h-5 ml-1 mr-3">
        Sign up with Google
      </a>
      <a
        v-if="false"
        href="/api/user/redirect_to_facebook"
        class="`
          flex px-2 py-1 m-1 text-sm font-bold bg-white
          border border-gray-300 rounded text-white
        `"
        style="background-color: #2877f2;"
      >
        <img
          src="/img/facebook/faecbook-icon.png"
          alt="google logo"
          class="flex-shrink-0 h-5 mr-2 w-7"
        >
        Sign up with Facebook
      </a>
      </div>

      <div class="flex flex-row items-center w-full justify-stretch ">
        <hr class="flex-grow">
        <span class="px-4 text-content">
          or
        </span>
        <hr class="flex-grow">
      </div>
    </div>

    <notification-banner
      v-if="errorMessage.length != 0"
      color="red"
    >
      {{ errorMessage }}
    </notification-banner>

    <form class="flex flex-col" @submit.prevent="register" v-if="!accountCreated">
      <text-input-component
        :value.sync="user.name"
        :valid="formTextValidator(user.name)"
        label="Name"
        mode="form"
      />
      <dropdown-component
        label="Country"
        :value.sync='user.country'
        :valid="formTextValidator(user.country)"
        :items="countries"
        mode="form"
      />

      <text-input-component
        :value.sync="user.postalCode"
        :valid="formPostalCodeValidator(user.postalCode)"
        label="Postal / Zip Code"
        mode="form"
        type="postalCode"
      />

      <text-input-component
        :value.sync="user.email"
        :valid="formEmailValidator"
        label="Email"
        type="email"
        mode="form"
      />

      <text-input-component
        :value.sync="user.password"
        :valid="formPasswordValidator(user.password)"
        label="Password"
        type="password"
        mode="form"
      />

      <label
        v-if="!accountCreated"
        class="text-content"
      >
        <input
          v-model="agreeToTerms"
          class="text-content"
          type="checkbox"
        >
        I agree to the
        <a
          class="underline cursor-pointer text-secondary"
          @click="$router.push({name:'TermsAndConditions'})"
        >
          Terms and Conditions
        </a>
      </label>

      <p
        class="mt-1 text-content text-bold"
      >
        View our
        <a
          class="underline cursor-pointer text-secondary"
          @click="$router.push({name: 'PrivacyPolicy'})"
        >
          Privacy Policy
        </a>
      </p>

      <button-large
        v-if="!accountCreated"
        class="self-center my-5"
        color="primary"
      >
        Register
      </button-large>

    </form>

    <p
      class="text-content text-bold"
      v-if="!accountCreated"
    >
      Already have an account?
      <a
        class="underline cursor-pointer text-secondary"
        @click="$router.push({name: 'SignIn'})"
      >
        Sign in
      </a>

    </p>
  </exterior-page-content-container>
</template>

<script>
import ExteriorPageContentContainer from '@/components/shared/containers/ExteriorPageContentContainer.vue';
import NotificationBanner from '@/components/shared/general/NotificationBanner.vue';

import { USER_CREATE } from '@/store/actions';
import * as countryJson from '@/assets/json/countries.json';
import TextInputComponent from '@/components/shared/inputs/TextInputComponent.vue';
import DropdownComponent from '@/components/shared/inputs/DropdownComponent.vue';
import { emailValidation, postalCodeValidation } from '@/utils/validators';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'Register',
  components: {
    ExteriorPageContentContainer,
    NotificationBanner,
    TextInputComponent,
    DropdownComponent,
    ButtonLarge,
  },
  data() {
    return {
      agreeToTerms: false,
      isValidating: false,
      formIsValid: true,
      errorMessage: '',
      accountCreated: false,
      countries: countryJson.countries,
      user: {
        name: '',
        country: '',
        postalCode: '',
        password: '',
        email: '',
      },
    };
  },
  computed: {
    formEmailValidator() {
      if (!this.isValidating) return true;
      if (emailValidation(this.user.email)) return true;
      return false;
    },
  },
  methods: {
    formTextValidator(value) {
      if (!this.isValidating) return true;
      if (value !== '') return true;
      return false;
    },
    formPostalCodeValidator() {
      if (!this.isValidating) return true;
      return postalCodeValidation(this.user.postalCode);
    },
    formPasswordValidator() {
      if (!this.isValidating) return true;
      if (this.user.password.length >= 8) return true;
      return false;
    },
    formFilledCheck() {
      if (this.user.name === '') return false;
      if (this.user.country === '') return false;
      if (this.user.email === '') return false;
      if (this.user.postalCode === '') return false;
      if (this.user.password === '') return false;
      return true;
    },
    register() {
      if (this.accountCreated) return;
      this.isValidating = true;
      this.errorMessage = '';

      if (!this.formFilledCheck()) {
        this.errorMessage = 'Please fill all fields';
      } else if (!this.formPostalCodeValidator()) {
        this.errorMessage = 'Invalid Postal / Zip Code';
      } else if (!this.formEmailValidator) {
        this.errorMessage = 'Invalid email address';
      } else if (!this.formPasswordValidator()) {
        this.errorMessage = 'Password to short, 8 char minimum';
      } else if (!this.agreeToTerms) {
        this.errorMessage = 'Please agree to Terms & Conditions';
      } else {
        this.executeUserCreate();
      }
    },
    executeUserCreate() {
      this.$store.dispatch(USER_CREATE, {
        name: this.user.name.trim(),
        email: this.user.email.trim(),
        country: this.user.country.trim(),
        postal_code: this.user.postalCode.trim(),
        password: this.user.password,
      })
        .then(() => {
          this.accountPending();
        })
        .catch((error) => {
          if (error.response.status === 400) this.errorMessage = error.response.data;
          else this.errorMessage = 'There appears to be an error. Please try again later';
        });
    },
    accountPending() {
      this.formIsValid = true;
      this.accountCreated = true;

      this.user.name = '';
      this.user.email = '';
      this.user.country = '';
      this.user.postalCode = '';
      this.user.password = '';

      this.agreeToTerms = false;
      this.errorMessage = '';
    },
  },
};
</script>
